var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('div',{staticClass:"text-h4 primary--text mb-5"},[_vm._v(" "+_vm._s(_vm.isUpdate ? 'แก้ไข' : 'สร้าง')+"หมวดหมู่ย่อยสินค้าขนส่ง ")]),_c('v-row',[_c('v-col',{staticClass:"pl-15",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.$v.form.name.$error ? _vm.$error_text : '',"label":"ชื่อหมวดหมู่ย่อย (สินค้าขนส่ง)","outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-select',{attrs:{"error-messages":_vm.$v.form.branch_id.$error ? _vm.$error_text : '',"items":_vm.list.branchs,"item-value":"_id","item-text":"branch_name","label":"สาขา (เลือกเพียง 1 สาขาเท่านั้น)","dense":"","outlined":"","multiple":""},model:{value:(_vm.form.branch_id),callback:function ($$v) {_vm.$set(_vm.form, "branch_id", $$v)},expression:"form.branch_id"}}),_c('v-text-field',{attrs:{"label":"ชื่อสำหรับรวมสินค้า","outlined":"","dense":""},model:{value:(_vm.form.merging_name),callback:function ($$v) {_vm.$set(_vm.form, "merging_name", $$v)},expression:"form.merging_name"}}),_c('v-textarea',{attrs:{"label":"หมายเหตุ","outlined":"","dense":""},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1),_c('v-col',{attrs:{"offset":"1"}},[_c('span',{staticClass:"mr-5"},[_vm._v("สถานะ")]),_c('menu-ship',{attrs:{"disabled":!_vm.isUpdate,"path":"sub_category/changed_sub_category_status","params":{ sub_category_id: _vm.form._id }},on:{"success":function (val) {
            _vm.$alertSuccess(), (_vm.form.is_available = val);
          }},model:{value:(_vm.form.is_available),callback:function ($$v) {_vm.$set(_vm.form, "is_available", $$v)},expression:"form.is_available"}})],1)],1),_c('v-toolbar',{staticClass:"mb-5",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"primary--text text-h5"},[_vm._v("ตารางสินค้า")]),_c('v-spacer'),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"ค้นหาชื่อสินค้า","outlined":"","rounded":"","dense":"","single-line":"","hide-details":""},on:{"keyup":_vm.getProducts},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"success","outlined":"","small":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.changedStatus(true)}}},[_vm._v(" เปิดใช้งาน ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","outlined":"","small":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.changedStatus(false)}}},[_vm._v("ปิดใช้งาน")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":"","disabled":!_vm.isUpdate},on:{"click":function($event){(_vm.product_id = 0), (_vm.dialogUpdate = true)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มสินค้า")],1)],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list.products,"search":_vm.search,"loading":_vm.loading,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","show-select":"","item-key":"_id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.is_available",fn:function(ref){
          var item = ref.item;
return [_c('menu-ship',{attrs:{"path":"product/changed_product_status","params":{ product_id: item._id }},on:{"success":function($event){_vm.$alertSuccess(), _vm.getProducts()}},model:{value:(item.is_available),callback:function ($$v) {_vm.$set(item, "is_available", $$v)},expression:"item.is_available"}})]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){(_vm.product_id = item._id), (_vm.dialogUpdate = true)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',[_c('v-col',{staticClass:"text-right py-10"},[_c('v-btn',{staticClass:"px-10 mr-15",attrs:{"color":"grey","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("ปิด")]),_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v("บันทึกข้อมูล")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1200"},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('ProductDetail',{attrs:{"id":_vm.product_id,"main_category":{ _id: _vm.form.category_id, name: _vm.category_name },"sub_category":{ _id: _vm.form._id, name: _vm.form.name },"dialogUpdate":_vm.dialogUpdate},on:{"onClose":function($event){_vm.dialogUpdate = false},"success":_vm.getProducts}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }